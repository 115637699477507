import { Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.scss']
})
export class CasinoComponent implements OnInit{

  casino_type:string='LIVECASINO';
  casino_data:any;
  userDetails:any;
  res_msg:any;

  constructor(private route: Router,private socket: Socket,private toastr: ToastrService,public httpClient:UsersService) 
  {
    this.userDetails=JSON.parse(sessionStorage.getItem('userDetails'));
   }

  ngOnInit(): void {
    this.casino_games(this.casino_type);
   }

  spinner(){
    this.route.navigate(['./wheel-spinner']);
  }

  casino_games(type:string)
  {
    this.casino_type=type;
    const data = {
      gameTypes:type,
      user:this.userDetails
    };
    this.httpClient.post_api('get-casinoidx/',data).subscribe((res:any) => {
       console.log(res);
       
       if(res.error)
       {
          this.res_msg=res.message;
       }
       else
       {
        this.casino_data=[];
        this.casino_data=res.response.items;
        // console.log(this.casino_data);
        
       }
     });
      
  }

  openCasino(gameID:string)
  {
    console.log(gameID);
    const data={gameId:gameID}; 
    // console.log(data);
    localStorage.setItem('casinoDb',JSON.stringify(data));
    this.route.navigate(['./casino-url']);
    // if(this.userDetails.details.betStatus)
    // {
    //   const data={gameId:gameID,tableId:tableID}; 
    //   localStorage.setItem('casinoDb',JSON.stringify(data));
    //   this.route.navigate(['./casino-url']);
    // }
    // else
    // {
    //   this.toastr.error('Error in placing bet.Bet Disable pls Contact Upline.');
    // }
    
  }
  
  
}
